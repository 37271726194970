import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { LoanRequestBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { LoanRequest } from "../../../../../requests/LoanRequest";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	listRoute: () => void;
	isEdit: boolean;
	groupList: any[];
	groupUser: any[];
	fetchGroupUser: (id: number) => Promise<any>;
	Groupdetails: any[];
	fetchGroupdetails: (id: number) => Promise<any>;
	preferenceList: any;
	loanAmt: any;
	setLoanAmt: (data: any) => void | any;
}


const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	saving,
	listRoute,
	groupList,
	groupUser,
	fetchGroupUser,
	fetchGroupdetails,
	preferenceList,
	loanAmt,
	setLoanAmt
}) => {
	const getGroupUsers = (id: any) => {
		form.setFieldsValue({ user_id: undefined });
		fetchGroupUser(id);
		fetchGroupdetails(id).then((data: any) => {
			setGroupdetails(data);

		});
	}
	const [groupdetails, setGroupdetails] = useState<any>([]);

	const { t } = useTranslation();

	const validateMax = (maxValue: any) => (_: any, value: any) => {
		if (parseFloat(value) >= parseFloat(maxValue)) {
			return Promise.reject(
				t("INITIAL_DEPOSIT_AMOUNT_VALIDATION_MSG")
			);
		} else if (maxValue == 0 || maxValue == undefined || maxValue == "") {
			return Promise.reject(
				t("PLEASE_ENTER_LOAN_AMOUNT_FIRST_THEN_YOU_CAN_ADD_INITIAL_DEPOSIT_AMOUNT")
			);
		}
		return Promise.resolve();
	};
	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={LoanRequestBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						{groupList && <InputBox.Select
							options={{
								list: groupList,
								valueKey: "id",
								textKey: "name"
							}}
							label={t("GROUP")}
							placeholder={t("GROUP")}
							name="group_id"
							onChange={getGroupUsers}
							rules={LoanRequest(t).group_id}
							showSearch
						/>}
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<p>{t("AMOUNT")} {groupUser && <i>{t("MAXIMUM_AMOUNT_OF_LOAN_AS_AMOUNT_CURRENCY", { amount: groupdetails?.borrowing_limit, currency: groupdetails?.currency?.name })}</i>}</p>
						<InputBox.Text
							size="large"
							placeholder={t("AMOUNT")}
							name="loan_amount"
							prefix={<UserOutlined />}
							rules={LoanRequest(t).loan_amount}
							onChange={(e) => {
								setLoanAmt(e.target.value)
								const amt = form.getFieldValue("initial_deposit_amount") ?? 0
								const actual_amt = e.target.value == "" ? 0 : parseFloat(e.target.value) - parseFloat(amt)
								form.setFieldsValue({ actual_loan_amount: actual_amt, initial_deposit_amount: 0 })
								form.setFields([
									{
										name: 'initial_deposit_amount',
										errors: [],
									},
								])
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<p>{t("INITIAL_DEPOSIT_AMOUNT")}</p>
						<InputBox.Text
							size="large"
							placeholder={t("INITIAL_DEPOSIT_AMOUNT")}
							name="initial_deposit_amount"
							initialValue={0}
							onChange={(e) => {
								const amt = form.getFieldValue("loan_amount") ?? 0
								setLoanAmt(form.getFieldValue("loan_amount"))
								const actual_amt = parseFloat(amt) - (e.target.value == "" ? amt : parseFloat(e.target.value))
								if (parseFloat(form.getFieldValue("loan_amount")) > 0) {
									form.setFieldsValue({ actual_loan_amount: actual_amt })
								}
							}}
							rules={[
								{ validator: validateMax(loanAmt) },
							]}
						/>
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<p>{t("ACTUAL_LOAN_AMOUNT")}</p>
						<InputBox.Text
							size="large"
							placeholder={t("ACTUAL_LOAN_AMOUNT")}
							name="actual_loan_amount"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						{groupUser && <InputBox.Select
							options={{
								list: groupUser,
								valueKey: "id",
								textKey: "user_name"
							}}
							label={t("USER")}
							placeholder={t("USER")}
							name="user_id"
							rules={LoanRequest(t).user_id}
							showSearch
						/>}
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							name="number_of_installments"
							placeholder={t("DURATION") + " (" + t("IN_MONTHS") + ")"}
							prefix={<UserOutlined />}
							label={t("DURATION") + " (" + t("IN_MONTHS") + ")"}
							rules={LoanRequest(t).number_of_installments}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.TextArea
							size="large"
							name="description"
							placeholder={t("DESCRIPTION")}
							prefix={<UserOutlined />}
							label={t("DESCRIPTION")}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Select
							required
							name="preference"
							placeholder={t("PREFERENCE")}
							label={t("PREFERENCE")}
							options={{
								list: preferenceList,
								valueKey: "id",
								textKey: "name"
							}}
						/>
					</Col>

				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
