import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";

const EditLoanRequestComponent: React.FC = observer(() => {
	const { ROOT, LOAN, COMMON } = useStore();
	const {
		update,
		details,
	} = LOAN;


	const [loan, setLoan] = useState<any>([])
	const [loanAmt, setLoanAmt] = useState<any>()
	const { id }: any = useParams();

	const {
		groupList,
		groupUser,
		fetchGroup,
		fetchGroupUser,
		Groupdetails,
		fetchGroupdetails,
		preferenceList
	} = COMMON;

	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		fetchGroup();
		details(id).then((data: any) => {
			setLoan(data);
			fetchGroupUser(data.group_id);
			form.resetFields();
			setLoanAmt(data.loan_amount)
			form.setFieldsValue({
				group_id: data.group_id,
				user_id: data.user_id,
				loan_amount: data.loan_amount,
				number_of_installments: data.number_of_installments,
				description: data.description,
				preference: data.payment_mode,
				actual_loan_amount: data.actual_loan_amount,
				initial_deposit_amount: data.initial_deposit_amount,
			});

		});
	}, [fetchGroup, fetchGroupUser, details, fetchGroupdetails])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () => {
		history.push('/loan-request-management');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.preference = data.preference.toString()
		data.initial_deposit_amount = data.initial_deposit_amount.toString()
		const formData = new FormData();
		data.group_id && formData.append("group_id", data.group_id);
		data.user_id && formData.append("user_id", data.user_id);
		data.preference && formData.append("preference", data.preference);
		data.loan_amount && formData.append("loan_amount", data.loan_amount);
		data.number_of_installments && formData.append("number_of_installments", data.number_of_installments);
		data.description && data.description?.trim() && formData.append("description", data.description);
		data.initial_deposit_amount && formData.append("initial_deposit_amount", data.initial_deposit_amount);

		update(loan.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="editLoanForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={true}
			groupList={groupList}
			groupUser={groupUser}
			fetchGroupUser={fetchGroupUser}
			fetchGroupdetails={fetchGroupdetails}
			Groupdetails={Groupdetails}
			preferenceList={preferenceList}
			loanAmt={loanAmt}
			setLoanAmt={setLoanAmt}
		/>
	);
});

export default EditLoanRequestComponent;
